import { computed, toValue } from 'vue'
import { useDisplay } from 'vuetify'

// Handle all non-Cloudinary images (currently on Digital Ocean Spaces)
// TODO: Deprecate
export function createImgSrc() {
  return function (image) {
    if (!image) return

    if (image.hash && image.ext) {
      return `${import.meta.env.VITE_UPLOADS_CDN}/${image.hash}${image.ext}`
    }
  }
}

// SEE: https://cloudinary.com/documentation/responsive_server_side_client_hints#automatic_image_width_using_optimal_responsive_breakpoints
// SEE: https://cloudinary.com/documentation/transformation_reference
function cloudinaryHref(image, transformation, params) {
  const parts = [
    '',
    import.meta.env.VITE_CLOUDINARY_CLOUD_NAME,
    'image',
    'upload'
  ]

  if (transformation && transformation.cloudinary_parameters && params) {
    //
    // Support new style transformation object defined in Directus
    //
    parts.push(
      transformation.cloudinary_parameters.replace(
        /{([.\w]+)}/g,
        (_, k) => params[k] + ''
      )
    )

    parts.push(image.filename_disk)
  } else {
    //
    // Support old style transformation object defined in JSON
    //
    const { crop, effects, gravity } = transformation ?? {}
    const { aspectRatio, width } = params ?? {}

    // ar_{aspectRatio}, {crop} | c_fill, {gravity}
    const arc = []
    if (aspectRatio) arc.push(`ar_${aspectRatio}`)
    if (crop) arc.push(crop)
    else arc.push('c_fill')
    if (gravity) arc.push(gravity)
    if (arc.length) parts.push(arc.join(','))

    // {effects}
    const eff = []
    if (Array.isArray(effects)) eff.push(...effects)
    if (eff.length) parts.push(eff.join(','))

    parts.push('f_auto')
    parts.push('q_auto:best')

    // c_limit, w_{width}
    const lim = []
    if (width) lim.push('c_limit', `w_${width}`)
    if (lim.length) parts.push(lim.join(','))

    if (image.public_id && image.extension)
      parts.push(`${image.public_id}.${image.extension}`)
    else if (image.filename_disk) parts.push(image.filename_disk)
  }

  return new URL(parts.join('/'), import.meta.env.VITE_CLOUDINARY_BASE).href
}

export function useImgResponsive(options) {
  const { thresholds } = useDisplay()

  return computed(() => {
    const value = toValue(options)
    const { image, sizes: sizesOpt } = value
    let { aspectRatio, transformation } = value

    if (aspectRatio === undefined) aspectRatio = 1
    aspectRatio = +aspectRatio.toFixed(5)

    const resp = {
      sizes: '',
      src: '',
      srcset: ''
    }

    if (image && image.src) {
      resp.src = image.src
    } else if (image && image.hash && image.ext) {
      resp.src = `${import.meta.env.VITE_UPLOADS_CDN}/${image.hash}${image.ext}`
    } else if (
      image &&
      ((image.public_id && image.extension) ||
        (image.filename_disk && image.storage === 'cloud'))
    ) {
      const sizes = []
      const srcset = []

      // Support new style transformation object defined in Directus
      transformation = transformation || image.transformations

      const src = cloudinaryHref(image, transformation, {
        aspectRatio,
        width: 1280 // Hardcoded default
      })

      for (const [bp, width] of Object.entries(thresholds.value)) {
        if (bp === 'xs') continue

        // If viewport width is equal to ${width}px or greater,
        // then show the image with a width of ${srcSize}
        const srcSize = sizesOpt?.[bp]
        if (srcSize) sizes.push(`(min-width: ${width}px) ${srcSize}`)

        const href = cloudinaryHref(image, transformation, {
          aspectRatio,
          width
        })
        srcset.push(`${href} ${width}w`)
      }

      sizes.push(sizesOpt?.xs || '100vw')

      resp.sizes = sizes.join(',')
      resp.src = src
      resp.srcset = srcset.join(',')
    }

    return resp
  })
}
